var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loader,"spinner-variant":"primary","spinner-type":"grow","spinner-lg":"","rounded":"sm"}},[_c('b-card',{staticClass:"mb-0 mb-5",attrs:{"border-variant":"info","header":"Modifier une villa","header-bg-variant":"light-info","header-text-variant":"white","header-tag":"header","header-class":"h2 "}},[_c('validation-observer',{ref:"villaRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleVillaSubmit.apply(null, arguments)}}},[_c('b-row',{staticClass:"justify-content-between mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',_vm._l((_vm.propriete.propriete_images),function(item){return _c('b-col',{key:item.id,attrs:{"md":"2"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('img',{staticClass:"img-fluid",attrs:{"id":"brandLogo","src":item.image_url,"alt":item.image}}),_c('a',{staticClass:"card-link float-right text-danger",attrs:{"title":"Supprimer"}},[_c('i',{staticClass:"fa fa-fw fa-lg fa-trash",on:{"click":function($event){$event.preventDefault();return _vm.deleteImage(item.id)}}})])])])])}),1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom de la villa","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"nom de la villa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Nom de la villa"},model:{value:(_vm.villaForm.name),callback:function ($$v) {_vm.$set(_vm.villaForm, "name", $$v)},expression:"villaForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sélectionner le propriétaire","label-for":"proprietaire_actuel"}},[_c('validation-provider',{attrs:{"name":"propriétaire","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"proprietaire_actuel","label":"name","size":"sm","state":errors.length > 0 ? false : null,"options":_vm.users,"placeholder":"Sélectionner le propriétaire","reduce":function (users) { return users.id; }},model:{value:(_vm.villaForm.proprietaire_actuel),callback:function ($$v) {_vm.$set(_vm.villaForm, "proprietaire_actuel", $$v)},expression:"villaForm.proprietaire_actuel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse","label-for":"adresse"}},[_c('validation-provider',{attrs:{"name":"adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"adresse","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Adresse"},model:{value:(_vm.villaForm.adresse),callback:function ($$v) {_vm.$set(_vm.villaForm, "adresse", $$v)},expression:"villaForm.adresse"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Type de résidence","label-for":"residence"}},[_c('validation-provider',{attrs:{"name":"residence","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-radio',{staticClass:"mr-4",attrs:{"plain":"","name":"residence","value":"Privée"},model:{value:(_vm.villaForm.residence),callback:function ($$v) {_vm.$set(_vm.villaForm, "residence", $$v)},expression:"villaForm.residence"}},[_vm._v(" Privée ")]),_c('b-form-radio',{attrs:{"plain":"","name":"residence","value":"En location"},model:{value:(_vm.villaForm.residence),callback:function ($$v) {_vm.$set(_vm.villaForm, "residence", $$v)},expression:"villaForm.residence"}},[_vm._v(" En location ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sélectionner les images de la villa","label-for":"fichier","label-size":"sm"}},[_c('validation-provider',{attrs:{"name":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"fileInput",attrs:{"id":"fichier","accept":"image/*","size":"sm","multiple":"","placeholder":"Choisir un fichier"},on:{"change":_vm.handleMultipleFilesSelected},scopedSlots:_vm._u([{key:"file-name",fn:function(ref){
var names = ref.names;
return [_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(names[0])+" ")]),(names.length > 1)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"dark"}},[_vm._v(" + "+_vm._s(names.length - 1)+" Fichiers ")]):_vm._e()]}}],null,true),model:{value:(_vm.villaForm.fileName),callback:function ($$v) {_vm.$set(_vm.villaForm, "fileName", $$v)},expression:"villaForm.fileName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.uploadProcess)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"success","disabled":"","block":"","size":"sm"}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Chargement... ")],1):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"my-2 float-right ml-2",attrs:{"variant":"danger","size":"sm","to":{ name: 'espace-admin-syndic.proprietes', replace: true }}},[_vm._v(" Annuler ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"my-2 float-right",attrs:{"variant":"info","size":"sm","disabled":invalid || _vm.proprieteProcess,"type":"submit"}},[_vm._v(" Modifier "),(_vm.proprieteProcess)?_c('b-spinner',{staticClass:"ml-1",attrs:{"variant":"light","type":"grow","small":""}}):_vm._e()],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }